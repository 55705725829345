//react
import { useState, useEffect } from "react";
//mui
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
//mui-x
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//3rd party
import { format } from "date-fns";
//assets
import CalendarSvg from "../../assets/call_history/Calendar.svg";

//calendar icon from figma
const CalendarIcon = (props) => {
  return (
    <Icon sx={{ display: "flex", alignItems: "center", ...props.sx }}>
      <img src={CalendarSvg} alt="calendar_svg" />
    </Icon>
  );
};

//individual date picker (custom dates only)
function SelectDate(props) {
  const [value, setValue] = useState(null);

  return (
    <Paper elevation={0} sx={{ ...props.sx }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={props.placeholder}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            if (props.date === "start") {
              props.setDate((prev) => {
                return { ...prev, startDate: newValue };
              });
            } else {
              props.setDate((prev) => {
                return { ...prev, endDate: newValue };
              });
            }
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Paper>
  );
}
//custom date selector component
export function CutomDateSelector(props) {
  const [expanded, setExpanded] = useState(false);
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  useEffect(() => {
    if (date.startDate !== null && date.endDate !== null) {
      props.setSearchKey((prev) => {
        return {
          ...prev,
          created_on_from: date.startDate
            ? format(new Date(date.startDate), "yyyy-MM-dd")
            : null,
          created_on_to: date.endDate
            ? format(new Date(date.endDate), "yyyy-MM-dd")
            : null,
        };
      });
    }
  }, [date, props]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{ boxShadow: "none", maxWidth: "350px" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Typography>
          <CalendarIcon sx={{ marginRight: "12px" }} />
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Select Custom Date
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex" }}>
        <SelectDate
          placeholder="Start Date"
          sx={{ ml: "1rem" }}
          date="start"
          setDate={setDate}
        />
        <SelectDate
          placeholder="End Date"
          sx={{ ml: "1rem" }}
          date="end"
          setDate={setDate}
        />
      </AccordionDetails>
    </Accordion>
  );
}
CutomDateSelector.defaultProps = {
  open: false,
  anchorEl: null,
  onClose: () => {},
};
