//Mui
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
//react
import React from "react";
//internal
import DatePick from "../date_picker/DatePick";
// import SortBy from "../sort_by/SortBy";

function SearchHeader(props) {
  const { standardUsersPage, ...restProps } = props;
  props = restProps;
  const [value, setValue] = React.useState(null);
  const [filterBy, setFilterBy] = React.useState("email");
  // const [sortBy, setSortBy] = React.useState(null);
  const handleChange = (event) => {
    setFilterBy(event.target.value);
  };
  const { setSearchKey } = props;
  // React.useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (sortBy !== null) {
  //       setSearchKey((prev) => {
  //         return {
  //           ...prev,
  //           credit_request_status: sortBy === "ALL" ? null : sortBy,
  //         };
  //       });
  //     }
  //   }, 1000);
  //   return () => clearInterval(timer);
  // }, [setSearchKey, sortBy]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (value !== null) {
        if (filterBy === "first_name") {
          if (standardUsersPage) {
            setSearchKey((prev) => {
              return {
                ...prev,
                first_name: value !== "" ? value : null,
                email: null,
                service: null,
              };
            });
          } else {
            setSearchKey((prev) => {
              return {
                ...prev,
                user_first_name: value !== "" ? value : null,
                user_email: null,
                service_name: null,
              };
            });
          }
        } else if (filterBy === "email") {
          if (standardUsersPage) {
            setSearchKey((prev) => {
              return {
                ...prev,
                email: value !== "" ? value : null,
                first_name: null,
                service: null,
              };
            });
          } else {
            setSearchKey((prev) => {
              return {
                ...prev,
                user_email: value !== "" ? value : null,
                user_first_name: null,
                service_name: null,
              };
            });
          }
        } else if (filterBy === "service_name") {
          if (standardUsersPage) {
            setSearchKey((prev) => {
              return {
                ...prev,
                email: null,
                first_name: null,
                service: value !== "" ? value : null,
              };
            });
          } else {
            setSearchKey((prev) => {
              return {
                ...prev,
                user_email: null,
                user_first_name: null,
                service_name: value !== "" ? value : null,
              };
            });
          }
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [value, setSearchKey, filterBy, standardUsersPage]);

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: "transparent",
        mt: ".4rem",
        ...props.sx,
      }}
      elevation={0}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems={"center"}>
          <TextField
            placeholder={props.searchBarPlaceholder}
            sx={{
              backgroundColor: "#F6F6F6",
              minWidth: "400px",
              marginRight: ".2rem",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          ></TextField>
          {/* filter by start */}
          <FormControl sx={{ m: 1, minWidth: "120px" }}>
            <Select
              value={filterBy}
              onChange={handleChange}
              defaultValue="email"
              displayEmpty
              sx={{ height: "53px" }}
            >
              {props.filterByMenu.map((item, index) => {
                return (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* filter by end */}
          {props.showDateFilter && (
            <DatePick setSearchKey={props.setSearchKey} sx={{ ml: "32px" }} />
          )}
          {/* {props.showSortBy && (
            <SortBy
              sx={{
                p: "13.5px 24px",
                borderRadius: "4px",
                ml: "16px",
              }}
              sortByValues={props.sortByValues}
              setSortBy={setSortBy}
            />
          )} */}
        </Box>

        <Box>
          {props.disableAddButton ? (
            <></>
          ) : (
            <Button
              variant="contained"
              disableElevation
              sx={{
                padding: "12px 24px",
                backgroundColor: "#2F7EC7",
                ":hover": {
                  backgroundColor: "#28659e",
                },
              }}
              onClick={props.handleClick}
            >
              {props.buttonTitle}
            </Button>
          )}
        </Box>
      </Stack>
    </Paper>
  );
}

SearchHeader.defaultProps = {
  buttonTitle: "Add",
  handleClick: () => {},
  disableAddButton: false,
  searchBarPlaceholder: "Search",
  setSearchKey: () => {},
  filterByMenu: [],
  sortByValues: [],
  sx: {},
};
export default React.memo(SearchHeader);
