//mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//encodings
import statusEncodings from "./statusEncoding.json";

//get color function
function getColorAndTitle(status) {
  return statusEncodings[status]
    ? statusEncodings[status]
    : {
        title: status,
        backgroundColor: "#EAEAEA",
        color: "#8A8A8A;",
      };
}
function getStatus(status, colorMode, sx) {
  return (
    <Box
      sx={{
        backgroundColor:
          colorMode === "bg"
            ? getColorAndTitle(status)["backgroundColor"]
            : "transparent",
        color:
          colorMode === "bg"
            ? getColorAndTitle(status)["color"]
            : colorMode === "text"
            ? getColorAndTitle(status)["color"]
            : "#0f0f0f",
        borderRadius: "5px",
        display: "flex",
        justifyContent: colorMode === "bg" ? "center" : "flex-start",
        alignItems: "center",
        maxWidth: "150px",
        py: colorMode === "bg" ? "8px" : 0,
        px: colorMode === "bg" ? "24px" : 0,
        ...sx,
      }}
    >
      <Typography component="span">{getColorAndTitle(status).title}</Typography>
    </Box>
  );
}

export default function Status({ status, colorMode, sx, ...props }) {
  status = status.toLowerCase();
  return getStatus(status, colorMode, sx);
}

Status.defaultProps = {
  status: "Status", //any
  colorMode: "bg", //text or bg
};
