import { Typography, Toolbar } from "@mui/material";

import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Chart({ data, xKey, yKey }) {
  return (
    <div
      style={{
        height: "100%",
        borderRadius: "10px",
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          style={{
            margin: 0,
            padding: "15px 0px 20px 10px",
            color: "#022539",
          }}
        >
          Credits Usage
        </Typography>
      </Toolbar>
      <ResponsiveContainer width="100%" height={320}>
        <AreaChart
          width="100%"
          height="100%"
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 90,
          }}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2CA9E3" stopOpacity={0.15} />
              <stop offset="95%" stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            tick={{ fill: "#939393", fontSize: 11 }}
            dataKey={xKey}
            tickLine={{ stroke: "transparent" }}
            stroke="#d9d9d9"
          />
          <YAxis
            tick={{ fill: "#939393", fontSize: 11 }}
            tickLine={{ stroke: "transparent" }}
            stroke="#d9d9d9"
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey={yKey}
            stroke="#2CA9E3"
            fillOpacity={1}
            fill="url(#colorPv)"
            activeDot={{ r: 8 }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
