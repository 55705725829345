//react
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
import Icon from "@mui/material/Icon";
//assets
import CalendarSvg from "../../assets/call_history/Calendar.svg";
//internal
import DateMenu from "./DateMenu";

//calendar icon from figma
const CalendarIcon = (props) => {
  return (
    <Icon sx={{ display: "flex", alignItems: "center", ...props.sx }}>
      <img src={CalendarSvg} alt="calendar_svg" />
    </Icon>
  );
};

//top class wrapper for whole component (root component)
function DatePick(props) {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const handleClick = (e) => {
    // console.log(e.currentTarget);
    setOpen((prev) => {
      return Boolean(e.currentTarget) ? true : false;
    });
    setAnchor(e.currentTarget);
  };
  const handleClose = (e) => {
    setOpen(false);
    setAnchor(null);
  };
  return (
    <Paper
      sx={{
        p: "12px 24px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
      }}
      elevation={0}
      onClick={handleClick}
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CalendarIcon sx={{ marginRight: "12px" }} />
        <Typography
          component="span"
          sx={{
            fontSize: "18px",
            lineHeight: "23px",
            color: "#0F0F0F",
            marginTop: "4px",
          }}
        >
          Filter by date
        </Typography>
      </Stack>
      <DateMenu
        anchor={anchor}
        open={open}
        handleClose={handleClose}
        setSearchKey={props.setSearchKey}
      />
    </Paper>
  );
}

export default DatePick;
