import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
//3rd party
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
//hoc
import AuthHOC from "../hoc/AuthHOC";
import useAxios from "../hooks/useAxios";
//utils
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
// import keys from "../keys.json";
import { Link } from "react-router-dom";

// material UI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";

// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import urls from "../utils/urls.json";
import { Button } from "@mui/material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Checkout({ dispatch }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState({});
  // const [error, setError] = useState(null);
  const { id } = useParams();
  const params = useLocation().search;
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const abortController = new AbortController();
  const breadcrumbs = [
    <Link key="1" to={urls.credits}>
      Credits
    </Link>,
    <Link key="2" to={urls.credit_requests}>
      Manage Credits
    </Link>,

    <Typography key="3" color="text.primary">
      Proceed to Checkout
    </Typography>,
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/request/${id}/`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setRequest(response.data);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
      setLoading(false);
    })();
    return () => abortController.abort();
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = async (event) => {
    const stripe = await stripePromise;
    let response;

    try {
      const config = axiosConfig({
        uri: `/credits/payment/stripe/`,
        method: "POST",
        data: { creditrequest_id: request.id },
      });
      response = await axiosInstance.current({
        ...config,
      });
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
    const sessionId = response?.data?.id;

    try {
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  return (
    !loading && (
      <>
        <Grid container spacing={3}>
          {/* START: Breadcrumb */}
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>{" "}
          {/* END: Breadcrumb */}
          <Grid item xs={12} style={{ marginTop: "54px" }}>
            <Paper elevation={0} sx={{ padding: "24px" }}>
              <Stack spacing={5}>
                <Stack
                  spacing={1}
                  direction="column"
                  paddingBottom="8px"
                  borderBottom="1px solid #EAEAEA"
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                    }}
                  >
                    Credits Payment Checkout
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={5}>
                  <Stack direction="column" spacing={5}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#898989",
                      }}
                    >
                      Total Credit Reqested{" "}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#898989",
                      }}
                    >
                      Price per Credit
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#898989",
                      }}
                    >
                      Total Price{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#898989",
                      }}
                    >
                      Purpose
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#898989",
                      }}
                    >
                      Mode of Payment{" "}
                    </Typography>
                  </Stack>

                  <Stack direction="column" spacing={5}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#0F0F0F",
                      }}
                    >
                      {parseFloat(request.credit.added).toFixed(0)}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#0F0F0F",
                      }}
                    >
                      $
                      {request.credit.payment.price /
                        request.credit.added /
                        100}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#0F0F0F",
                      }}
                    >
                      ${request.credit.payment.price / 100}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#0F0F0F",
                      }}
                    >
                      {request.credit.purpose}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#0F0F0F",
                      }}
                    >
                      {request.credit.payment.mode === "STRIPE" && "Stripe"}
                      {request.credit.payment.mode === "CHEQUE" && "Cheque"}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{ padding: "16px 24px 16px 24px", maxWidth: "677px" }}
            >
              <Stack spacing={3}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={5}
                >
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#898989",
                      }}
                    >
                      Total Price
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "28px",
                        fontWeight: "700",
                        color: "#0F0F0F",
                      }}
                    >
                      $ {request.credit.payment.price / 100}
                    </Typography>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#2F7EC7",
                        float: "right",
                        clear: "right",
                        padding: "16px 24px 16px 24px",
                        maxWidth: "250px",
                      }}
                      type="button"
                      role="link"
                      onClick={handleClick}
                      style={{ cursor: "pointer" }}
                      disabled={loading}
                      size="small"
                      disableElevation
                    >
                      Proceed to checkout
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  );
}
export default AuthHOC(Checkout);
