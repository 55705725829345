export default function Error(props) {
    let style = {
        width: 'auto',
        padding: '10px 15px',
        border: '1px solid #000',
        boxShadow: '2px 2px #777',
        color: 'red'
    }
    
    return (
        <div style={style}>{props.message}</div>
    );
}