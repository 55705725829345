//mui imports
import Paper from "@mui/material/Paper";
//assests
import { ReactComponent as NurobitPSGLogo } from "../../assets/logo/NeurobitPSGLogo.svg";
export default function Loading() {
  return (
    <Paper
      elevation={0}
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NurobitPSGLogo />
    </Paper>
  );
}
